/* .dash {
    background-color: #f2f2f2;
    padding: 1em;
    margin: 0.5em;
    border-radius: 1em;
} */
/* 
.header {
    margin: 1em;
    font-size: 1.25rem;
    color: #0e0e0e;
} */

.debug {
    background-color: rgba(0, 0, 0, 0.5);
    white-space: pre-wrap;
    padding: 1em;
    border-radius: 1em;
}

.embeds {
    margin-top: 1em;
    min-width: 95vw;
}

.Paper
.embeds > div {
    border-radius: 1em;
    background-color: #fff;
}

.embeds > div iframe {
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
}

