.home {
  text-align: center;
  font-size: calc(10px + 2vmin);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.logo {
  width: 192px;
  margin: 0 auto;
}
